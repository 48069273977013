import axios from "axios";
import React, { useState, useEffect } from "react";
import { FormGroup, Label, Input, Button, Alert } from "reactstrap";

import { create as createImage } from "../image/axios";

// import ControldocFormTemplateFilePicker from './file_picker';

import ControldocFormImageImagePicker from '../image/image_picker';

import {
  inputId,
  inputName,
  drawErrors,
  drawTooltipHelp
} from "../../helper/form";

import I18n from "i18n-js";

import Select from 'react-select'

const ControldocFormTemplateForm = props => {
  const formName = props?.formName || "controdoc_form_template";
  const currentUser = props?.currentUser || {};
  const { indexPath, showPath, createAction, updateAction} = props?.actions || {};
  const currentEntityType = props?.currentEntityType || {};

  const [template, setTemplate] = useState(props?.template || {});
  const [templateName, setTemplateName] = useState(props?.template?.name);
  const [isControldocConsentAnnex, setIsControldocConsentAnnex] = useState(props?.template?.is_controldoc_consent_annex);
  const [ templateLayoutId, setTemplateLayoutId ] = useState(props?.template?.template_layout_id);
  const [templateContent, setTemplateContent] = useState(props?.template?.content)
  const [templateVars, setTemplateVars] = useState([]);

  const [tinymceEditor, setTinymceEditor] = useState();
  const [openFilePicker, setOpenFilePicker] = useState(false);
  const [imageList, setImageList] = useState(props?.imagePicker?.imageList || []);
  const allowedFormatImagePicker = props?.imagePicker?.allowedFormatFiles || []

  const [errors, setErrors] = useState(props?.errors || {})

  const varsDataTypes = [
    {
      name: "date",
      label: "Fecha",
      type: "date",
      input_type: "date",
      class: "",
    },
    {
      name: "text",
      label: "Texto",
      type: "text",
      input_type: "text",
      class: "",
    },
    {
      name: "money",
      label: "Formato Moneda",
      type: "money",
      input_type: "text",
      class: "input-money",
    },
    {
      name: "number",
      label: "Número",
      type: "number",
      input_type: "number",
      class: "",
    },
  ];

  useEffect(() => {
    tinyMCE.init({
      selector: `#${inputId(formName, "content")}`,
      language: "es",
      height: 500,
      entity_encoding: "raw",
      plugins: "preview advlist lists table charmap fullscreen link image pagebreak code",
      toolbar:
        "varMenuButton | uploadFile | bold italic underline forecolor backcolor | undo redo | link image code | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | print | charmap | fullscreen code",
      image_caption: true,
      a11y_advanced_options: true,
      relative_urls: false,
      remove_script_host: false,
      pagebreak_separator: "<div class='alwaysbreak'></div>",
      promotion: false,

      setup: editor => {
        editor.ui.registry.addButton('uploadFile', {
          text: 'Imágenes',
          icon: 'image',
          onAction: _ => {
            setTinymceEditor(editor)
            setOpenFilePicker(true)
          }
        });


        editor.ui.registry.addMenuButton("varMenuButton", {
          text: "Crear Variables",
          fetch: callback => {
            var items = _.map(varsDataTypes, function (_var) {
              return {
                type: "menuitem",
                text: _var.label,
                onAction: () => {
                  let content = editor.selection.getContent();
                  const nonAlphanumericRegex = /([^a-zA-Z\d:])/g;
                  const whitespaceRegex = /([\s])/g;

                  content =
                    _.deburr(content)
                      .replaceAll(nonAlphanumericRegex, "_")
                      .replaceAll(whitespaceRegex, "_")
                      .replaceAll(".", "")
                      .toLowerCase() || _.uniqueId(`${_var.name}_`);
                  editor.insertContent(`[[${content}]/${_var.name}/]`);
                },
              };
            });
            callback(items);
          },
        });
      },
      init_instance_callback: editor => {
        editor.on("Change", e => {
          let text = e.target.getContent();
          let vars = getAllVariableFromText(text);
          setTemplateContent(text);
          setTemplateVars(vars);
        });
      },
    });
  });

  useEffect(() => {
    let _vars = getAllVariableFromText(templateContent || "");
    setTemplateVars(_vars);
  }, []);

  const getAllVariableFromText = data => {
    // let re = /(\[\[\w+\]\])+/g;
    let re = /(\[\[\w+\]\/\w+\/\])+/g;

    let _vars = data.match(re);

    let vars = _.map(_vars, function (_var) {
      var __reType = /\/\w+\/+/g;
      var __type = _var.match(__reType)[0];

      return {
        var: _var,
        name: _var.replace(__type, ""),
        type: __type?.replaceAll("/", ""),
      };
    });

    return vars;
  };

  const drawVariables = () => {
    if(templateVars.length > 0){
      return(
        <div className="card">
          <div className="card-body">
            <h6 className="card-subtitle mb-2 text-muted">
              Variables utilizadas
            </h6>

            { _.map(templateVars, function(_var, index){
              return(
                <span
                  className="badge badge-secondary mr-2"
                  key={ _.uniqueId("span-var") }
                >
                  { `${ _var.name } - ${ _var.type}` }
                </span>
              )
            })}

          </div>
        </div>
      )
    } else {
      return(
        <div className="card">
          <div className="card-body">
            <Alert color="warning" className="text-center">
              Aún no tienes variables
            </Alert>

            { drawErrorsVars() }
          </div>
        </div>
      )
    }
  }

  const drawErrorsVars = () => {
    if(_.has(errors, "vars")){
      return(
        <ul className="errors_list">
          { _.map(errors["vars"], function(error){
            return(
              <li key={ _.uniqueId("error_vars") }>{ error }</li>
            )
          })}
        </ul>
      )
    }
  }

  const getTemplateLayoutSelectedOption = () => {
    let selected = _.find(props?.data?.templateLayouts, { id: templateLayoutId })

    if(selected){
      return { value: selected.id, label: selected.name }
    } else {
      return null;
    }
  }

  const drawSelectTemplateLayoutOptions = () => {
    return _.map(props?.data?.templateLayouts, templateLayout => ( {label: templateLayout.name, value: templateLayout.id }))
  }

  const drawTemplateLayoutsInputSelect = () => {
    return(
      <FormGroup>
        <Label
          for={ inputId(formName, "template_layout_id") }
        >
          { I18n.t("activerecord.attributes.controldoc_form/template.template_layout_id") }
        </Label>

        <Select
          styles={{
            // Fixes the overlapping problem of the component
            menu: provided => ({ ...provided, zIndex: 9999 })
          }}
          id={ inputId(formName, 'template_layout_id') }
          name={ inputName(formName, 'template_layout_id') }
          invalid={ _.has(errors, 'template_layout_id') }
          value={ getTemplateLayoutSelectedOption() }
          onChange={ e => setTemplateLayoutId( e?.value )}
          options={ drawSelectTemplateLayoutOptions() }
          isClearable={ true }
          placeholder={ `-- Selecciona un ${ I18n.t('activerecord.attributes.controldoc_form/template.template_layout_id') } --` }
        />

        { drawErrors(errors, "template_layout_id") }
      </FormGroup>
    )
  }

  const getFormData = () => {
    let formData = new FormData();

    formData.append(`${ formName }[id]`, template?.id || "");
    formData.append(`${ formName }[name]`, templateName || "");
    formData.append(`${ formName }[content]`, templateContent || "");
    formData.append(`${ formName }[parent_id]`, template?.parent_id || "");
    formData.append(`${ formName }[is_controldoc_consent_annex]`, isControldocConsentAnnex);
    formData.append(`${ formName }[template_layout_id]`, templateLayoutId || '');

    _.each(templateVars, _var => {
      formData.append(
        `${ formName }[vars][${ _var.name }][name]`, _var.name
      )

      formData.append(
        `${ formName }[vars][${ _var.name }][type]`, _var.type
      )

      formData.append(
        `${ formName }[vars][${ _var.name }][var]`, _var.var
      )
    });

    return formData;
  }

  const handleSubmit = e => {
    swalWithBootstrap.fire({
      title: I18n.t("controldoc_form.templates.form.confirm.title"),
      html: I18n.t("controldoc_form.templates.form.confirm.message"),
    }).then( result => {
      if(result.isConfirmed){
        handleSave();
      }
    })
  }

  const handleSave = () => {
    let formData = getFormData();

    // Si existe en currentEntityType, la ruta cambie por el parametro del EntityType para el modulo
    if(_.keys(currentEntityType).length > 1) {
      if(template.id){
        updateAction({id: template.id, currentEntityTypeId: currentEntityType.hashid}, formData, response => {
          handleResponse(response)
        })
      } else {
        createAction(currentEntityType.hashid, formData, response => {
          handleResponse(response)
        })
      }
    } else {
      // Cuando no tiene el entity type
      if(template.id){
        updateAction(template.id, formData, response => {
          handleResponse(response);
        })
      } else{
        createAction(formData, response => {
          handleResponse(response);
        })
      }
    }
  }

  const handleResponse = response => {
    if(response.status == 200 || response.status == 201){

      if(_.keys(currentEntityType).length > 1){
        window.location = showPath(currentEntityType.hashid, response.data.id);
      } else {
        window.location = showPath(response.data.id);
      }
    } else {
      setErrors(response.data);
    }
  }

  const drawIsControldocConsentAnnexSwitchInput = () => {
    return(
      <FormGroup>
        <div className='custom-switch'>
          <Input
            className='custom-control-input'
            type='checkbox'
            name={ inputName(formName, 'is_controldoc_consent_annex') }
            id={ inputId(formName, 'is_controldoc_consent_annex') }
            onChange={ e => setIsControldocConsentAnnex(e.target.checked) }
            checked={ isControldocConsentAnnex }
            value={ '1' }
            style={{ display: 'contents' }}
          />
          <Label
            className='custom-control-label'
            for={ inputId(formName, 'is_controldoc_consent_annex') }
          >
            { I18n.t(`activerecord.attributes.controldoc_form/template.is_controldoc_consent_annex?`) }
            { drawTooltipHelp(I18n.t('controldoc_form.templates.form.is_controldoc_consent_annex_help')) }
          </Label>
        </div>
      </FormGroup>
    )
  }

  return(
    <div className="row">
      <div className="col-12">
        <ControldocFormImageImagePicker
          openFilePicker={ openFilePicker }
          setOpenFilePicker={ setOpenFilePicker }
          imageList={ imageList }
          tinymceEditor={ tinymceEditor }
          allowedFormatImagePicker={ allowedFormatImagePicker }
        />

        <FormGroup>
          <Label
            for={ inputId(formName, "name") }
            className="required"
          >
            { I18n.t("activerecord.attributes.controldoc_form/template.name") }
          </Label>

          <Input
            type="text"
            id={ inputId( formName, "name") }
            name={ inputName(formName, "name") }
            value={ templateName || "" }
            onChange={ e => setTemplateName(e?.target?.value) }
            invalid={ _.has(errors, "name") }
          />

          { drawErrors(errors, "name") }
        </FormGroup>
        { drawIsControldocConsentAnnexSwitchInput() }
        { drawTemplateLayoutsInputSelect() }

        <FormGroup>
          { drawVariables() }
        </FormGroup>

        <FormGroup>
          <Label
            for={ inputId(formName, "content") }
            className="required"
          >
            { I18n.t("activerecord.attributes.controldoc_form/template.content") }
          </Label>

          <Input
            type="textarea"
            id={ inputId(formName, "content") }
            name={ inputName(formName, "content") }
            value={ templateContent || "" }
            onChange={ e => setTemplateContent(e?.target?.value) }
          />
          { drawErrors(errors, "content") }
        </FormGroup>

        <FormGroup>
          <div className="col-12 text-end mt-3">
            <a
              href={ indexPath }
              className="btn btn-default"
            >
              { I18n.t("actions.back") }
            </a>

            <Button
              type="button"
              className="ml-2"
              color="success"
              onClick={ e => handleSubmit(e) }
            >
              { I18n.t('actions.save') }
            </Button>
          </div>
        </FormGroup>
      </div>
    </div>
  )
}

export default ControldocFormTemplateForm;
